<template>
  <m-widget-container class="d-flex flex-column">
    <div class="map-container-with-categories">
      <div
        class="map-container"
        :class="{ 'list-open': listOpen }"
      >
        <resize-observer @notify="resize" />
        <div
          id="map"
          ref="map"
          :class="{ 'center-shifted': centerShifted }"
        />
        <div
          id="popup"
          ref="popup"
        >
          <v-slide-y-reverse-transition>
            <v-card
              v-if="selectedMarker"
              class="pa-1"
              elevation="10"
              raised
            >
              <v-card-title class="justify-space-between pr-1 pt-1">
                <v-chip
                  label
                  outlined
                >
                  <v-avatar
                    tile
                    left
                  >
                    <v-img
                      contain
                      :src="featureLayers[selectedMarker.layer].icon"
                    />
                  </v-avatar>
                  {{ featureLayers[selectedMarker.layer].title }}
                </v-chip>
                <v-btn
                  class="close-popup"
                  fab
                  text
                  @click="closePopup"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-if="popupContentClean"
                  v-html="popupContentClean"
                />
                <!-- eslint-enable vue/no-v-html -->
                <div
                  v-else
                  id="popupContent"
                >
                  <b>{{ selectedMarker.title }}</b>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="showLocationQR = !showLocationQR"
                >
                  WEG ZUM ZIEL
                </v-btn>
              </v-card-actions>
              <v-dialog
                v-model="showLocationQR"
                width="300px"
              >
                <v-card>
                  <v-card-title class="justify-space-between pr-1 pt-1">
                    Weg zum Ziel
                    <v-btn
                      class="close-popup"
                      fab
                      text
                      @click="showLocationQR = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="d-flex justify-center pt-3 pb-10">
                    <div ref="qart" />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-slide-y-reverse-transition>
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-layout
            column
            align-center
            justify-center
          >
            <v-progress-circular
              indeterminate
              size="64"
            />
            <v-subheader>
              {{ loadingText }}
            </v-subheader>
          </v-layout>
        </v-overlay>
        <v-sheet
          v-if="listOpen"
          :color="transparent(options.categoriesBackground)"
          class="sub-categories"
        >
          <v-list
            dense
            color="transparent"
            dark
            flat
            class="pa-0"
          >
            <v-list-item-group
              v-model="selectedLayers"
              multiple
              active-class="list-active"
            >
              <v-list-item
                v-for="layerName in availableLayerNames"
                :key="layerName"
                class="ma-1"
                color="primary lighten-2"
              >
                <v-list-item-avatar
                  tile
                  size="2rem"
                >
                  <v-img
                    contain
                    :src="featureLayers[layerName].icon"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  {{ featureLayers[layerName].title }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </div>
      <v-sheet
        class="map-layer-controls"
      >
        <v-overlay
          v-if="!options.center"
          absolute
        >
          <v-card>
            <v-alert type="warning">
              Please configure the center coordinates to render the map.
            </v-alert>
          </v-card>
        </v-overlay>
        <template v-else-if="categories.length">
          <v-slide-group
            v-model="selectedCategory"
            class="layer-buttons"
            active-class="category-active"
          >
            <v-slide-item
              v-slot="{ active, toggle }"
            >
              <v-card
                width="100%"
                :color="options.categoriesBackground"
                flat
                tile
                @click="toggle"
              >
                <v-container class="d-flex flex-column justify-center align-center">
                  <v-avatar
                    :color="active ? 'primary' : 'primary lighten-5'"
                  >
                    <v-icon
                      large
                      color="black"
                    >
                      mdi-check-all
                    </v-icon>
                  </v-avatar>
                  <span
                    class="caption mt-2"
                    :class="{ 'font-weight-bold': active }"
                  >
                    Alle Kategorien
                  </span>
                </v-container>
              </v-card>
            </v-slide-item>
            <v-slide-item
              v-for="(category, i) in categories"
              :key="i"
              v-slot="{ active, toggle }"
            >
              <v-card
                width="100%"
                :color="options.categoriesBackground"
                flat
                tile
                @click="toggle"
              >
                <v-container class="d-flex flex-column justify-center align-center">
                  <v-avatar
                    :color="active ? 'primary' : 'primary lighten-5'"
                  >
                    <v-img
                      width="2em"
                      height="2em"
                      contain
                      :src="category.icon"
                    />
                  </v-avatar>
                  <span
                    class="caption mt-2"
                    :class="{ 'font-weight-bold': active }"
                  >
                    {{ category.title }}
                  </span>
                </v-container>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </template>
      </v-sheet>
    </div>
  </m-widget-container>
</template>

<script>
// open layers imports
import Map from 'ol/Map.js'
import View from 'ol/View.js'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js'
import Feature from 'ol/Feature.js'
import { Cluster, Vector as VectorSource } from 'ol/source.js'
import { Style, Icon } from 'ol/style.js'
import { Zoom, ScaleLine, Rotate, ZoomSlider, Control } from 'ol/control.js'
import { defaults as defaultInteractions, DragRotateAndZoom } from 'ol/interaction.js'
import OSM from 'ol/source/OSM.js'
import { fromLonLat, toLonLat } from 'ol/proj.js'
import Point from 'ol/geom/Point.js'
import Overlay from 'ol/Overlay.js'
import { containsExtent } from 'ol/extent.js'
import proj4 from 'proj4'
// other imports
import QArt from 'qartjs'
import 'ol/ol.css'

import $storage from '../../../plugins/local_storage.js'
import { mWidgetMixin } from '../../../mixins'

proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')
const logo = require('./assets/qrLogo.png')

export default {
  name: 'MMap',
  mixins: [mWidgetMixin],
  props: {
    options: {
      type: Object,
      default: () => ({
        center: [7.0747832, 51.1627533],
        showOrientation: false,
        orientationAngle: 0,
        useOfflineStorage: undefined,
        offlineStorageRadiusKm: 100
      })
    },
    categories: {
      type: Array,
      default: () => []
    },
    layers: {
      type: Array,
      default: () => []
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: true,
      loadingText: 'Preparing Map',
      centerCoordinates: fromLonLat(this.options.center),
      map: undefined,
      view: undefined,
      popup: undefined,
      baseLayer: undefined,
      tileGrid: undefined,
      featureLayers: {},
      selectedCategory: 0,
      visibleLayers: [],
      selectedLayers: [],
      selectedLonLat: [],
      selectedMarker: null,
      showLocationQR: false,
      centerShifted: false,
      paintedLayers: {},
      // cache extent values were manually set
      cacheExtent: [
        765746.1493608952, // minx
        6637103.60303014, // miny
        800219.2491175085, // maxx
        6667296.229202785 // maxy
      ]
    }
  },
  computed: {
    availableLayerNames() {
      if (!this.selectedCategory) {
        return Object.keys(this.featureLayers)
      }
      const selectedCategoryIndex = this.selectedCategory - 1
      const selectedCategoryId = this.categories[selectedCategoryIndex].id
      return this.layers.filter(l => l.category === selectedCategoryId).map(l => l.id).flat()
    },
    popupContentClean() {
      const popupContent = this.selectedMarker ? this.selectedMarker.popupContent : null
      return popupContent ? popupContent.replace(/(<br>)+/g, '<br>') : null
    },
    listOpen() {
      // open the list if a category is selected and has more than one layer
      return this.selectedCategory && this.availableLayerNames.length > 1
    }
  },
  watch: {
    showLocationQR(state) {
      if (state) {
        const locationURL = this.selectedLonLat.length ? `https://maps.google.com/?q=${this.selectedLonLat[1]},${this.selectedLonLat[0]}` : null
        this.$nextTick(() => {
          const { offsetWidth } = this.$refs.qart.parentNode
          const qart = new QArt({
            value: locationURL,
            imagePath: logo,
            filter: 'color',
            background: '#fdd835',
            size: offsetWidth,
            version: 10
          })
          qart.make(this.$refs.qart)
        })
      }
    },
    availableLayerNames(availableLayerNames) {
      this.selectedLayers = []
    },
    selectedLayers(selectedLayers) {
      if (!selectedLayers.length) {
        this.visibleLayers = [...new Set(this.availableLayerNames.flat())]
      } else {
        this.visibleLayers = selectedLayers.map(x => this.availableLayerNames[x])
      }
    },
    visibleLayers(visibleLayers) {
      Object.keys(this.featureLayers).forEach((featureLayer) => {
        const layer = this.paintedLayers[featureLayer]
        if (layer) {
          if (!visibleLayers.includes(featureLayer)) {
            layer.setVisible(false)
          } else {
            layer.setVisible(true)
          }
        }
      })
    }
  },
  mounted() {
    this.view = new View({
      projection: 'EPSG:3857',
      center: this.centerCoordinates,
      zoom: 16,
      minZoom: 12
    })
    const point = new Point(this.centerCoordinates)
    const youAreHere = new Feature({
      geometry: point,
      name: 'Sie sind gerade hier.',
      type: 'no_click'
    })
    const youAreFacing = new Feature({
      geometry: point,
      type: 'no_click'
    })
    this.baseLayer = new TileLayer({
      type: 'base',
      source: new OSM({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        tileLoadFunction: this.options.useOfflineStorage ? this.tileLoadFunction : null
      })
    })

    const layers = [
      // The Base Map Layer
      this.baseLayer,
      // The You Are Here Layer
      new VectorLayer({
        source: new VectorSource({
          features: [youAreHere]
        }),
        style: new Style({
          image: new Icon({
            src: require('./assets/youAreHere.svg'),
            scale: [0.15],
            color: '#FF3333'
          })
        }),
        zIndex: 2
      })
    ]
    if (this.options.showOrientation) {
      layers.push(
        // The You Are Facing Layer
        new VectorLayer({
          source: new VectorSource({
            features: [youAreFacing]
          }),
          style: new Style({
            image: new Icon({
              src: require('./assets/youAreFacing.png'),
              color: '#95d3ff',
              rotation: (this.options.orientationAngle / 180) * Math.PI
            })
          }),
          zIndex: 2
        })
      )
    }

    const panToCoords = this.centerCoordinates
    const backToStart = 'Mein Standort'
    const PanToStartControl = (function ol(Control) {
      function PanToStartControl(optOptions) {
        const options = optOptions || {}
        const button = document.createElement('button')
        button.innerHTML = backToStart
        const element = document.createElement('div')
        element.className = 'ol-unselectable ol-control ol-pan-to-start'
        element.appendChild(button)
        Control.call(this, {
          element,
          target: options.target
        })
        button.addEventListener('click', this.handlePanToStart.bind(this), false)
      }
      if (Control) {
        Object.setPrototypeOf(PanToStartControl, Control)
      }
      PanToStartControl.prototype = Object.create(Control && Control.prototype)
      PanToStartControl.prototype.constructor = PanToStartControl
      PanToStartControl.prototype.handlePanToStart = function handlePanToStart() {
        this.getMap().getView().animate({
          center: panToCoords,
          duration: 1000,
          zoom: 16
        })
      }
      return PanToStartControl
    }(Control))

    this.map = new Map({
      controls: [
        new Zoom(),
        new ScaleLine(),
        new Rotate(),
        new ZoomSlider(),
        new PanToStartControl()
      ],
      interactions: defaultInteractions().extend([
        new DragRotateAndZoom()
      ]),
      layers,
      target: 'map',
      view: this.view
    })
    this.popup = new Overlay({
      element: this.$refs.popup,
      positioning: 'bottom-center',
      stopEvent: true,
      offset: [0, -20],
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    })
    this.map.addOverlay(this.popup)
    this.map.on('click', this.mapOnClick)
    this.map.on('moveend', this.mapOnMoveEnd)
    this.paintFeatureLayers()
    this.resize()
    this.loading = false
    this.$emit('loaded')
  },
  methods: {
    resize() {
      this.map.updateSize()
    },
    tileInCacheBoundary(ImageTile) {
      const tileCoord = ImageTile.getTileCoord()
      // zoom, x, y
      const [z, x, y] = tileCoord
      if (!this.tileGrid) {
        this.tileGrid = this.baseLayer.getSource().getTileGrid()
      }
      const tileGridOrigin = this.tileGrid.getOrigin(z)
      const tileSizeAtResolution = this.tileGrid.getTileSize(z) * this.tileGrid.getResolution(z)
      const tileExtent = [
        tileGridOrigin[0] + tileSizeAtResolution * x,
        -1 * (tileGridOrigin[0] + tileSizeAtResolution * y),
        tileGridOrigin[0] + tileSizeAtResolution * (x + 1),
        -1 * (tileGridOrigin[0] + tileSizeAtResolution * (y + 1))
      ]
      return containsExtent(this.cacheExtent, tileExtent)
    },
    tileLoadFunction(ImageTile, src) {
      const imgElement = ImageTile.getImage()
      // check if image data for src is stored in your cache
      const { value, ttl } = $storage.get(`map:${src}`, true)
      if (value && ttl > 0) {
        imgElement.src = value
        return
      }
      // only save to local storage if in cacheExtent
      if (this.tileInCacheBoundary(ImageTile)) {
        // save image dataSRC to local storage upon load
        imgElement.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = imgElement.width
          canvas.height = imgElement.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(imgElement, 0, 0)
          const data = canvas.toDataURL()
          $storage.set(`map:${src}`, data, 86400000)
          console.log('saved map tile')
        }
      } else {
        console.log(`map:${src} tile outside extent, did not save to local storage`)
      }
      if (value) {
        // add 10 minutes to the TTL and load again to use tile from local storage
        imgElement.onerror = () => {
          $storage.setTTL(`map:${src}`, 10 * 60 * 1000)
          ImageTile.load()
        }
      }
      // start loading image from url
      imgElement.src = src
    },
    mapOnClick(evt) {
      const feature = this.map.forEachFeatureAtPixel(evt.pixel, (feature) => {
        return feature
      }, { hitTolerance: 10 })
      if (feature && feature.values_.features && feature.values_.features[0].values_.featureType !== 'no_click') {
        const coordinates = feature ? feature.getGeometry().getCoordinates() : [0, 0]
        this.popup.setPosition(coordinates)
        this.selectedLonLat = toLonLat(feature.values_.geometry.flatCoordinates)
        this.selectedMarker = feature.values_.features[0].values_
        this.showLocationQR = false
        return
      }
      this.closePopup()
    },
    closePopup() {
      this.popup.setPosition(0, 0)
      this.selectedMarker = null
      this.selectedLonLat = []
      this.showLocationQR = false
    },
    mapOnMoveEnd() {
      const currentCenter = this.view.getCenter()
      const changeX = window.Math.abs(currentCenter[0] - this.centerCoordinates[0])
      const changeY = window.Math.abs(currentCenter[1] - this.centerCoordinates[1])
      this.centerShifted = (changeX + changeY) > 200
    },
    paintFeatureLayers() {
      this.layers.forEach((layer) => {
        if (!(layer.id in this.featureLayers)) {
          const newLayer = Object.assign({}, layer)
          newLayer.style = new Style({
            image: new Icon({
              src: this.mImage({ src: newLayer.icon, w: 32, h: 32, fit: 'contain' })
            }),
            pointRadius: 200
          })
          this.featureLayers[newLayer.id] = newLayer
          const features = this.markers.filter(marker => marker.layer === newLayer.id).map((marker) => {
            const coords = fromLonLat(marker.coordinates)
            const feature = Object.assign({}, marker)
            feature.geometry = new Point(coords)
            return new Feature(feature)
          })
          const vectorSource = new VectorSource({ features })
          const clusterSource = new Cluster({
            distance: 30,
            source: vectorSource
          })
          const vectorLayer = new VectorLayer({
            source: clusterSource,
            style: newLayer.style
          })
          this.paintedLayers[newLayer.id] = vectorLayer
          this.map.addLayer(vectorLayer)
        }
      })
    },
    transparent(color, opacity) {
      if (!color) {
        return null
      }
      const hex = this.$vuetify.theme.themes.light[color] || color
      const transparency = opacity || 'AA'
      return `${hex}${transparency}`
    }
  }
}
</script>

<style lang="sass">
  @import 'vuetify/src/styles/settings/_colors'
  .map-container-with-categories
    position: relative
    width: 100%
    flex: 1
    display: flex
    flex-direction: column
    .map-container
      position: relative
      width: 100%
      flex: 1
      display: flex
      flex-direction: column
      #map
        flex: 1
        height: 100%
        .ol-control button
          width: 2rem
          height: 2rem
        .ol-attribution
          display: none
        .ol-zoomslider
          top: 6em
        &:not(.center-shifted)
          .ol-pan-to-start
            display: none
        &.center-shifted
          .ol-pan-to-start
            bottom: 1em
            right: 1em
            button
              width: auto
              padding: 0 10px
      #popup
        min-height: 250px
        min-width: 400px
        padding-left: 50px
        display: flex
        align-items: flex-end
        > div
          position: relative
          width: 100%
        #popupContent
          display: flex
          flex-direction: column
          color: black
          a
            display: none
          b
            font-size: 1.5em
      .sub-categories
        position: absolute
        top: 0
        bottom: 0
        right: 0
        width: 25%
        min-width: 200px
        max-width: 300px
        // scrolls
        ::-webkit-scrollbar
          -webkit-appearance: none
          width: 7px
        ::-webkit-scrollbar-track
          width: 7px
          border-radius: 1rem
          background-color: transparent
        ::-webkit-scrollbar-thumb
          width: 7px
          background-color: #bed600
          border-radius: 1rem
        > div
          width: 100%
          height: 100%
          overflow-y: scroll
      &.list-open
        #popup
          padding-right: 300px
        .ol-unselectable.ol-control.ol-pan-to-start
          padding-right: 300px
    .map-layer-controls
      position: initial
      flex-shrink: 0
      // .theme--dark.v-card
        // background: map-get($blue-grey, 'darken-3')
      .layer-buttons
        width: 100%
        display: flex
        justify-content: space-around
        align-items: center
        text-align: center
        .v-btn
          flex-grow: 1
          .v-btn__content
            flex-direction: column
            text-transform: initial
        .v-responsive.v-image
          flex: initial
          margin: 10px 0
    .v-list-item--active.list-active, .category-active
      color: #bed600!important
    .v-slide-group__content
      .v-card--link:before
        background: initial
</style>
